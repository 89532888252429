import React from "react";

import Group from "./hexagons/Group";
import Agency from "./hexagons/Agency";

import { LEFT_OFFSET, TOP_OFFSET } from "../positions/constants";

const Example = () => {
  const scale = 0.3;
  return (
    <section>
      <div
        className="chart-center"
        style={{
          top: "50%",
          left: "50%",
          transform: `scale(${scale})`,
          transformOrigin: "center",
        }}
      >
        <div className="chart-item">
          <Agency
            agency={{
              title: "PARENT",
              image:
                "http://localhost:3000/uploads/agencies/1624392809788-download-png",
            }}
          />
        </div>
        {/*  1 */}
        <div
          className="chart-item"
          style={{ top: `-${2 * TOP_OFFSET}px`, left: 0 }}
        >
          <Group
            group={{
              title: "1",
              subTitle: "0",
            }}
          />
        </div>
        <div
          className="chart-item"
          style={{ top: `-${4 * TOP_OFFSET}px`, left: 0 }}
        >
          <Group
            group={{
              title: "1",
              subTitle: "1",
            }}
          />
        </div>
        {/*  1 */}
        {/* 2 */}
        <div
          className="chart-item"
          style={{ top: `-${TOP_OFFSET}px`, left: `${LEFT_OFFSET}px` }}
        >
          <Group
            group={{
              title: "2",
              subTitle: "0",
            }}
          />
        </div>
        <div
          className="chart-item"
          style={{ top: `-${2 * TOP_OFFSET}px`, left: `${2 * LEFT_OFFSET}px` }}
        >
          <Group
            group={{
              title: "2",
              subTitle: "1",
            }}
          />
        </div>
        <div
          className="chart-item"
          style={{ top: `-${3 * TOP_OFFSET}px`, left: `${3 * LEFT_OFFSET}px` }}
        >
          <Group
            group={{
              title: "2",
              subTitle: "2",
            }}
          />
        </div>
        {/* 2 */}
        {/* 3 */}
        <div
          className="chart-item"
          style={{ top: `-${TOP_OFFSET}px`, left: `${LEFT_OFFSET * 3}px` }}
        >
          <Group
            group={{
              title: "3",
            }}
          />
        </div>
        {/* 3 */}
        {/*  4 */}
        <div
          className="chart-item"
          style={{ top: 0, left: `${2 * LEFT_OFFSET}px` }}
        >
          <Group
            group={{
              title: "4",
              subTitle: "0",
            }}
          />
        </div>
        <div
          className="chart-item"
          style={{ top: 0, left: `${4 * LEFT_OFFSET}px` }}
        >
          <Group
            group={{
              title: "4",
              subTitle: "1",
            }}
          />
        </div>
        <div
          className="chart-item"
          style={{ top: 0, left: `${6 * LEFT_OFFSET}px` }}
        >
          <Group
            group={{
              title: "4",
              subTitle: "2",
            }}
          />
        </div>
        <div
          className="chart-item"
          style={{ top: 0, left: `${8 * LEFT_OFFSET}px` }}
        >
          <Group
            group={{
              title: "4",
              subTitle: "3",
            }}
          />
        </div>
        {/* 4 */}
        {/* 5 */}
        <div
          className="chart-item"
          style={{ top: `${TOP_OFFSET}px`, left: `${LEFT_OFFSET * 3}px` }}
        >
          <Group
            group={{
              title: "5",
              subTitle: "0",
            }}
          />
        </div>
        <div
          className="chart-item"
          style={{ top: `${TOP_OFFSET * 2}px`, left: `${LEFT_OFFSET * 6}px` }}
        >
          <Group
            group={{
              title: "5",
              subTitle: "1",
            }}
          />
        </div>
        <div
          className="chart-item"
          style={{ top: `${TOP_OFFSET * 3}px`, left: `${LEFT_OFFSET * 9}px` }}
        >
          <Group
            group={{
              title: "5",
              subTitle: "2",
            }}
          />
        </div>
        {/* 5 */}
        {/*  6 */}
        <div
          className="chart-item"
          style={{ top: `${TOP_OFFSET}px`, left: `${LEFT_OFFSET}px` }}
        >
          <Group
            group={{
              title: "6",
            }}
          />
        </div>
        {/*  6 */}
        {/*  7 */}
        <div
          className="chart-item"
          style={{ top: `${2 * TOP_OFFSET}px`, left: 0 }}
        >
          <Group
            group={{
              title: "7",
            }}
          />
        </div>
        {/*  7 */}
        {/*  8 */}
        <div
          className="chart-item"
          style={{ top: `${TOP_OFFSET}px`, left: `-${LEFT_OFFSET}px` }}
        >
          <Group
            group={{
              title: "8",
            }}
          />
        </div>
        {/*  8 */}
        {/*  9 */}
        <div
          className="chart-item"
          style={{ top: `${TOP_OFFSET}px`, left: `-${LEFT_OFFSET * 3}px` }}
        >
          <Group
            group={{
              title: "9",
            }}
          />
        </div>
        {/*  9 */}
        {/*  10 */}
        <div
          id="start"
          className="chart-item"
          style={{ top: 0, left: `-${LEFT_OFFSET * 2}px` }}
        >
          <Group
            group={{
              title: "10",
              subTitle: "O",
            }}
          />
        </div>
        <div
          id="end"
          className="chart-item"
          // style={{ top: `-${TOP_OFFSET}px`, left: `-${LEFT_OFFSET * 6}px` }}
          style={{ top: 0, left: `-${LEFT_OFFSET * 6}px` }}
        >
          <Group
            group={{
              title: "10",
              subTitle: "1",
            }}
          />
        </div>
        {/*  10 */}
        {/*  11 */}
        <div
          className="chart-item"
          style={{ top: `-${TOP_OFFSET}px`, left: `-${LEFT_OFFSET * 3}px` }}
        >
          <Group
            group={{
              title: "11",
            }}
          />
        </div>
        {/*  11 */}
        {/* 12 */}
        <div
          className="chart-item"
          style={{ top: `-${TOP_OFFSET}px`, left: `-${LEFT_OFFSET}px` }}
        >
          <Group
            group={{
              title: "12",
            }}
          />
        </div>
        {/* 12 */}
      </div>
    </section>
  );
};

export default Example;
