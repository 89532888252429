import axios from "axios";
import { useQuery } from "react-query";

const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH;

const getQuery = (path) => async () => {
  let data;
  try {
    const res = await axios.get(`${API_BASE_PATH}/${path}`, {
      withCredentials: true,
    });
    data = res.data;
  } catch (error) {
    throw error.response;
  }
  return data;
};

export const useGet = (keyNames, path = keyNames[0]) =>
  useQuery(keyNames, keyNames.length > 0 ? getQuery(path) : () => null, {
    retry: false,
  });
