import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Slide from "@material-ui/core/Slide";
import Box from "@material-ui/core/Box";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { SnackbarProvider } from "notistack";
import "./scss/index.scss";

import Page from "./components/Page";
import Example from "./components/Example";
import ExampleParent from "./components/Example-parent";
import "./libs/i18n";

const queryClient = new QueryClient();

const App = () => (
  <SnackbarProvider
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    TransitionComponent={Slide}
  >
    <Box display="flex">
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        {/* <UserProvider> */}
        <Router>
          <Switch>
            <Route
              path="/"
              exact
              component={() => {
                window.location.href = process.env.REACT_APP_ADMIN_PATH;
                return null;
              }}
            />
            <Route exact path="/example">
              <Example />
            </Route>
            <Route exact path="/parent-example">
              <ExampleParent />
            </Route>
            <Route exact path="/:organigramId/:hideExport?">
              <Page />
            </Route>
          </Switch>
        </Router>
        {/* </UserProvider> */}
      </QueryClientProvider>
    </Box>
  </SnackbarProvider>
);

export default App;
