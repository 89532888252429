import React, { useEffect } from "react";
import $ from "jquery";

// import LeaderLine from "leader-line-new";
import Group from "./hexagons/Group";
import Agency from "./hexagons/Agency";

import { getPosition, oppositPosition } from "../positions";

const distanceBetweenElems = (elem1, elem2) => {
  const e1Rect = elem1.getBoundingClientRect();
  const e2Rect = elem2.getBoundingClientRect();
  const dx =
    e1Rect.left +
    (e1Rect.right - e1Rect.left) / 2 -
    (e2Rect.left + (e2Rect.right - e2Rect.left) / 2);
  const dy =
    e1Rect.top +
    (e1Rect.bottom - e1Rect.top) / 2 -
    (e2Rect.top + (e2Rect.bottom - e2Rect.top) / 2);
  const dist = Math.sqrt(dx * dx + dy * dy);
  return dist;
};

const Example = () => {
  const scale = 0.3;
  useEffect(() => {
    const size = 6 * scale;
    $(".chart-container").each((i, chartContainer) => {
      const parent = $(chartContainer).find(".chart-item.parent")[0];
      if (parent) {
        $(chartContainer)
          .children(".chart-item.children")
          .each((y, children) => {
            const startAnchorId = $(children).data("start-anchor");
            const startAnchor = $(parent).find(`.anchor-${startAnchorId}`)[0];
            const endAnchorId = $(children).data("end-anchor");
            const endAnchor = $(children).find(`.anchor-${endAnchorId}`)[0];
            if (endAnchor && startAnchor) {
              $(startAnchor).addClass("start");
              $(endAnchor).addClass("end");
              const line = $(startAnchor).find(`.line`)[0];
              const width =
                distanceBetweenElems(startAnchor, endAnchor) / scale;
              $(line).css({ width });
              // const line = new LeaderLine(
              //   LeaderLine.pointAnchor(startAnchor),

              //   LeaderLine.pointAnchor(endAnchor),
              //   {
              //     color: "#bfbfbf",
              //     path: "straight",
              //     size,
              //     endPlug: "behind",
              //     startPlugSize: 0,
              //   },
              // );
            }
          });
      }
    });
  }, []);
  return (
    <section id="chart">
      <div
        className="chart-center chart-container"
        style={{
          top: "50%",
          left: "50%",
          transform: `scale(${scale})`,
          transformOrigin: "center",
        }}
      >
        <div className="chart-item parent">
          <Agency
            agency={{
              title: "PARENT",
              image:
                "http://localhost:3000/uploads/agencies/1624392809788-download-png",
            }}
          />
        </div>
        {Array(12)
          .fill()
          .map((o, i) => (
            <div
              key={i}
              className="chart-item children"
              data-start-anchor={`${i + 1}`}
              data-end-anchor={`${oppositPosition[i + 1]}`}
              style={getPosition(i + 1, 1)}
            >
              <Group
                group={{
                  title: `${i + 1}`,
                }}
              />
            </div>
          ))}
      </div>
    </section>
  );
};

export default Example;
