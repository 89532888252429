import React from "react";
import PropTypes from "prop-types";

import Hexagon from "./Hexagon";
import personPng from "../../img/png/person.png";

const Profile = ({ profile }) => {
  let profileImg = profile.image;
  if (profileImg && process.env.NODE_ENV !== "development") {
    profileImg = profileImg.replace("http://", "https://");
  }
  return (
    <Hexagon
      keyName="profile"
      link={profile.link}
      borderColor={profile.color}
      isHighlight={profile.isHighlight}
    >
      <div className="firstname">{profile.firstname}</div>
      <div className="lastname">{profile.lastname}</div>
      <div className="position">{profile.position}</div>
      <div className="hexagon-image">
        <img src={profileImg || personPng} />
      </div>
    </Hexagon>
  );
};
Profile.defaultProps = {
  profile: {
    firstname: "",
    lastname: "",
    position: "",
  },
};

Profile.propTypes = {
  profile: PropTypes.object,
};

export default Profile;
