import { LEFT_OFFSET, TOP_OFFSET } from "./constants";

export const getPosition = (pos, depth) => {
  switch (pos) {
    case 1: {
      return { top: `-${2 * (1 + depth) * TOP_OFFSET}px`, left: 0 };
    }
    case 2: {
      return {
        top: `-${TOP_OFFSET * (1 + depth)}px`,
        left: `${LEFT_OFFSET * (1 + depth)}px`,
      };
    }
    case 3: {
      return {
        top: `-${TOP_OFFSET * (1 + depth)}px`,
        left: `${LEFT_OFFSET * 3 * (1 + depth)}px`,
      };
    }
    case 4: {
      return { top: 0, left: `${2 * (depth + 1) * LEFT_OFFSET}px` };
    }
    case 5: {
      return {
        top: `${TOP_OFFSET * (1 + depth)}px`,
        left: `${LEFT_OFFSET * 3 * (1 + depth)}px`,
      };
    }
    case 6: {
      return {
        top: `${TOP_OFFSET * (1 + depth)}px`,
        left: `${LEFT_OFFSET * (1 + depth)}px`,
      };
    }
    case 7: {
      return { top: `${2 * (1 + depth) * TOP_OFFSET}px`, left: 0 };
    }
    case 8: {
      return {
        top: `${TOP_OFFSET * (1 + depth)}px`,
        left: `-${LEFT_OFFSET * (1 + depth)}px`,
      };
    }
    case 9: {
      return {
        top: `${TOP_OFFSET * (1 + depth)}px`,
        left: `-${LEFT_OFFSET * 3 * (1 + depth)}px`,
      };
    }
    case 10: {
      return { top: 0, left: `-${2 * (depth + 1) * LEFT_OFFSET}px` };
    }
    case 11: {
      return {
        top: `-${TOP_OFFSET * (1 + depth)}px`,
        left: `-${LEFT_OFFSET * 3 * (1 + depth)}px`,
      };
    }
    case 12: {
      return {
        top: `-${TOP_OFFSET * (1 + depth)}px`,
        left: `-${LEFT_OFFSET * (1 + depth)}px`,
      };
    }

    default:
      return { top: 0, left: `${2 * LEFT_OFFSET}px` };
  }
};

export const oppositPosition = {
  1: 7,
  2: 8,
  3: 9,
  4: 10,
  5: 11,
  6: 12,
  7: 1,
  8: 2,
  9: 3,
  10: 4,
  11: 5,
  12: 6,
};
const SEPARATOR_TOP_OFFSET = 100;
const SEPARATOR_LEFT_OFFSET = 180;

const tempTop = 100;
const tempLeft = 58;
export const getSeparatorPosition = (pos, depth) => {
  switch (pos) {
    case 1: {
      return { top: `-${(1 + depth) * TOP_OFFSET - 75}px`, left: 0 };
    }
    case 2: {
      return {
        top: `-${TOP_OFFSET * (1 + depth) - tempTop}px`,
        left: `${LEFT_OFFSET * (1 + depth) - tempLeft}px`,
      };
    }
    case 3: {
      return {
        top: `-${SEPARATOR_TOP_OFFSET * (1 + depth)}px`,
        left: `${SEPARATOR_LEFT_OFFSET * (1 + depth)}px`,
      };
    }
    case 4: {
      return { top: 0, left: `${2 * (depth + 1) * LEFT_OFFSET - 115}px` };
    }
    case 5: {
      return {
        top: `${SEPARATOR_TOP_OFFSET * (1 + depth)}px`,
        left: `${SEPARATOR_LEFT_OFFSET * (1 + depth)}px`,
      };
    }
    case 6: {
      return {
        top: `${TOP_OFFSET * (1 + depth) - tempTop}px`,
        left: `${LEFT_OFFSET * (1 + depth) - tempLeft}px`,
      };
    }
    case 7: {
      return { top: `${(1 + depth) * TOP_OFFSET - 75}px`, left: 0 };
    }
    case 8: {
      return {
        top: `${TOP_OFFSET * (1 + depth) - tempTop}px`,
        left: `-${LEFT_OFFSET * (1 + depth) - tempLeft}px`,
      };
    }
    case 9: {
      return {
        top: `${SEPARATOR_TOP_OFFSET * (1 + depth)}px`,
        left: `-${SEPARATOR_LEFT_OFFSET * (1 + depth)}px`,
      };
    }
    case 10: {
      return { top: 0, left: `-${2 * (depth + 1) * LEFT_OFFSET - 115}px` };
    }
    case 11: {
      return {
        top: `-${SEPARATOR_TOP_OFFSET * (1 + depth)}px`,
        left: `-${SEPARATOR_LEFT_OFFSET * (1 + depth)}px`,
      };
    }
    case 12: {
      return {
        top: `-${TOP_OFFSET * (1 + depth) - tempTop}px`,
        left: `-${LEFT_OFFSET * (1 + depth) - tempLeft}px`,
      };
    }

    default:
      return { top: 0, left: `${2 * LEFT_OFFSET}px` };
  }
};

export const getRotation = (pos) => {
  switch (pos) {
    case 4:
    case 10: {
      return 90;
    }
    case 8:
    case 2: {
      return 30;
    }
    case 9:
    case 3: {
      return 60;
    }
    case 11:
    case 5: {
      return -60;
    }
    case 12:
    case 6: {
      return -30;
    }

    default:
      return 0;
  }
};

export const getSeparatorStyle = (pos, size) => {
  const diagX = 0;
  const diagY = 70;
  const style = {
    width: `${300 * size}px`,
  };
  switch (pos) {
    case 1: {
      style.transform = "rotate(0deg) translate(0px, -70px)";
      break;
    }
    case 2: {
      style.transform = `rotate(30deg) translate(0px, -70px)`;
      break;
    }
    case 3: {
      style.transform = "rotate(60deg) translate(0px, -70px)";
      break;
    }
    case 5: {
      style.transform = "rotate(-60deg) translate(0px, 70px)";
      break;
    }
    case 4: {
      style.transform = "rotate(90deg) translate(0, -70px)";
      break;
    }
    case 6: {
      style.transform = `rotate(-30deg)  translate(0px, 70px)`;
      break;
    }
    case 7: {
      style.transform = "rotate(0deg) translate(0px, 70px)";
      break;
    }
    case 8: {
      style.transform = `rotate(30deg)  translate(0px, 70px)`;
      break;
    }
    case 9: {
      style.transform = "rotate(60deg) translate(0px, 70px)";
      break;
    }
    case 10: {
      style.transform = "rotate(90deg) translate(0, 70px)";
      break;
    }
    case 11: {
      style.transform = "rotate(-60deg) translate(0px, -70px)";
      break;
    }
    case 12: {
      style.transform = `rotate(-30deg)  translate(0px, -70px)`;
      break;
    }

    default:
      style.transform = "rotate(0deg) translate(0px, 0px)";
      break;
  }
  return style;
};
