import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useGet } from "../hooks/requests";

import ParentContainer from "./ParentContainer";
import SpeedDials from "./SpeedDials";

export const BASE_ORGANIGRAMS_PATH = "organigrams";

const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const dataKey = "organigramDetail";
const Page = () => {
  const params = useParams();
  const classes = useStyles();
  const keyNames = [`${dataKey}-${params.organigramId}`];
  const apiUrl = `${BASE_ORGANIGRAMS_PATH}/${params.organigramId}`;
  const { isLoading, error, data } = useGet(keyNames, apiUrl);
  if (isLoading) return <div>LOADING</div>;
  if (error && error.status === 404) return <div>NOT FOUND</div>;
  return (
    <div>
      {data && (
        <>
          <section id="chart">
            <div
              data-top={`${data.top_position}%`}
              data-left={`${data.left_position}%`}
              data-scale={`${data.zoom / 100}`}
              id="chart-center"
              className="chart-center chart-container"
              style={{
                top: `${data.top_position}%`,
                left: `${data.left_position}%`,
                transform: `scale(${data.zoom / 100})`,
                transformOrigin: "center",
              }}
            >
              {data.structure && data.structure[0] && (
                <ParentContainer
                  block={data.structure[0]}
                  scale={data.zoom}
                  top={data.top_position}
                  left={data.left_position}
                />
              )}
            </div>
          </section>
          {!params.hideExport && (
            <div className={classes.speedDial}>
              <SpeedDials data={data} uuid={params.organigramId} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Page;
