import React from "react";
import PropTypes from "prop-types";

const Hexagon = ({ children, keyName, borderColor, link, isHighlight }) => (
  <div
    className={`hexagon ${keyName} ${isHighlight ? "highlight" : ""}`}
    style={{ borderColor }}
  >
    <div className="before" style={{ borderColor }}></div>
    <div className="after" style={{ borderColor }}></div>
    {link && (
      <a href={link} className="link" target="_blank" rel="noreferrer" />
    )}
    {Array(12)
      .fill()
      .map((o, i) => (
        <div key={i} className={`anchor anchor-${i + 1}`}>
          <div className="anchor-middle">
            <div className="line" />
          </div>
        </div>
      ))}
    <div className="hexagon-content">{children}</div>
    <span className="hexagon-shadow" />
  </div>
);
Hexagon.defaultProps = {
  children: <></>,
  keyName: "",
  borderColor: "#000",
  isHighlight: false,
  link: null,
};

Hexagon.propTypes = {
  children: PropTypes.node,
  keyName: PropTypes.string,
  link: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  borderColor: PropTypes.string,
  isHighlight: PropTypes.bool,
};

export default Hexagon;
