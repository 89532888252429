import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  iframeCode: {
    width: "100%",
    resize: "none",
  },
}));

const IframeDialog = ({ onClose, open, data }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [width, setWidth] = useState("100%");
  const [height, setHeight] = useState("800px");
  const [zoom, zetZoom] = useState(100);
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="iframe-dialog"
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="delete-dialog">{t("iframe.title")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl variant="outlined">
              <TextField
                id="iframe-width"
                type="text"
                label={t("iframe.width")}
                value={width}
                onChange={(e) => setWidth(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="outlined">
              <TextField
                id="iframe-height"
                type="text"
                label={t("iframe.height")}
                value={height}
                onChange={(e) => setHeight(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="outlined">
              <TextField
                id="iframe-zoom"
                type="number"
                label={t("iframe.zoom")}
                value={zoom}
                onChange={(e) => zetZoom(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Box mt={2} mb={2}>
          <TextareaAutosize
            className={classes.iframeCode}
            aria-label="iframe-code"
            rowsMin={3}
            placeholder=""
            value={`<iframe src="${
              window.location.href
            }/1" style="width:${width};height:${height};border:none;transform: scale(${
              zoom / 100
            });" title="${data.title}"></iframe>`}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("iframe.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

IframeDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
};

export default IframeDialog;
