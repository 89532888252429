import React from "react";
import PropTypes from "prop-types";
import Hexagon from "./Hexagon";

const Group = ({ group }) => (
  <Hexagon keyName="group" borderColor={group.color} link={group.link}>
    <div
      className="title"
      style={{
        fontSize: group.titlefontsize ? `${group.titlefontsize}px` : "50px",
      }}
    >
      {group.title}
    </div>

    {group.subtitle && (
      <div
        className="subTitle"
        style={{
          fontSize: group.subtitlefontsize
            ? `${group.subtitlefontsize}px`
            : "40px",
        }}
      >
        {group.subtitle}
      </div>
    )}
  </Hexagon>
);
Group.defaultProps = {
  group: {
    title: "",
  },
};

Group.propTypes = {
  group: PropTypes.object,
};

export default Group;
