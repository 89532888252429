import React, { useState } from "react";
import download from "downloadjs";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SpeedDial from "@material-ui/lab/SpeedDial";
import ImageIcon from "@material-ui/icons/Image";
import CodeIcon from "@material-ui/icons/Code";
import ShareIcon from "@material-ui/icons/Share";
import CircularProgress from "@material-ui/core/CircularProgress";
import $ from "jquery";
import domtoimage from "dom-to-image-more";
import IframeDialog from "./IframeDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
  speedDial: {
    position: "fixed",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
}));

const SpeedDials = ({ data, uuid }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [openIframe, setOpenIframe] = useState(false);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleImageExport = () => {
    const bounds = {
      top: null,
      bottom: null,
      left: null,
      right: null,
    };
    const offset = 20;
    const topPosition = $("#chart-center").data("top");
    const leftPosition = $("#chart-center").data("left");
    const scale = $("#chart-center").data("scale");
    $("#chart-center").css({
      transform: "scale(1)",
    });
    $("body").toggleClass("hidden-scroll");
    setIsLoading(true);
    $(".chart-container").each((i, chartContainer) => {
      $(chartContainer)
        .find(".chart-item")
        .each((y, chartIrem) => {
          const chartBounds = chartIrem.getBoundingClientRect();
          if (!bounds.top || (bounds.top && bounds.top > chartBounds.top)) {
            bounds.top = chartBounds.top;
          }
          if (
            !bounds.bottom ||
            (bounds.bottom && bounds.bottom < chartBounds.bottom)
          ) {
            bounds.bottom = chartBounds.bottom;
          }
          if (!bounds.left || (bounds.left && bounds.left > chartBounds.left)) {
            bounds.left = chartBounds.left;
          }
          if (
            !bounds.right ||
            (bounds.right && bounds.right < chartBounds.right)
          ) {
            bounds.right = chartBounds.right;
          }
        });
    });
    if (bounds.top && bounds.bottom) {
      bounds.height = bounds.bottom - bounds.top + offset * 2;
    }
    if (bounds.left && bounds.right) {
      bounds.width = bounds.right - bounds.left + offset * 2;
    }
    $("#chart-center").css({
      top: `calc(${topPosition} - ${bounds.top - offset}px)`,
      left: `calc(${leftPosition} - ${bounds.left - offset}px)`,
    });
    const node = document.getElementById("chart");
    domtoimage
      .toPng(node, {
        width: bounds.width,
        height: bounds.height,
        bgcolor: "transparent",
      })
      .then((dataUrl) => {
        download(dataUrl, `${uuid}.png`);
        $("#chart-center").css({
          top: topPosition,
          left: leftPosition,
          transform: `scale(${scale})`,
        });
        $("body").toggleClass("hidden-scroll");
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        // eslint-disable-next-line no-console
        console.error("oops, something went wrong!", error);
      });
  };

  const actions = [
    {
      icon: <ImageIcon />,
      name: "speedDial.image",
      onClick: () => handleImageExport(),
    },
    {
      icon: <CodeIcon />,
      name: "speedDial.iframe",
      onClick: () => setOpenIframe(true),
    },
  ];
  return (
    <div>
      {isLoading && (
        <div className="loading-container">
          <CircularProgress />
        </div>
      )}
      <SpeedDial
        ariaLabel="SpeedDial example"
        className={classes.speedDial}
        icon={<ShareIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="up"
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={t(action.name)}
            onClick={() => action.onClick()}
          />
        ))}
      </SpeedDial>

      <IframeDialog
        data={data}
        open={openIframe}
        onClose={() => setOpenIframe(false)}
      />
    </div>
  );
};

SpeedDials.propTypes = {
  data: PropTypes.object.isRequired,
  uuid: PropTypes.string.isRequired,
};

export default SpeedDials;
