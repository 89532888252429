import React from "react";
import PropTypes from "prop-types";
import { getSeparatorStyle } from "../positions";

const Separator = ({ separator }) => (
  <div
    className="separator"
    style={getSeparatorStyle(separator.pos, separator.size)}
  ></div>
);
Separator.defaultProps = {
  separator: {
    size: 1,
  },
};

Separator.propTypes = {
  separator: PropTypes.object,
};

export default Separator;
