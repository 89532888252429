import React, { useEffect } from "react";
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";
import classNames from "classnames";
import PropTypes from "prop-types";
import Profile from "./hexagons/Profile";
import Agency from "./hexagons/Agency";
import Group from "./hexagons/Group";
import Separator from "./Separator";

import {
  getPosition,
  oppositPosition,
  getSeparatorPosition,
} from "../positions";

export const BASE_ORGANIGRAMS_PATH = "organigrams";
const distanceBetweenElems = (elem1, elem2) => {
  const e1Rect = elem1.getBoundingClientRect();
  const e2Rect = elem2.getBoundingClientRect();
  const dx =
    e1Rect.left +
    (e1Rect.right - e1Rect.left) / 2 -
    (e2Rect.left + (e2Rect.right - e2Rect.left) / 2);
  const dy =
    e1Rect.top +
    (e1Rect.bottom - e1Rect.top) / 2 -
    (e2Rect.top + (e2Rect.bottom - e2Rect.top) / 2);
  const dist = Math.sqrt(dx * dx + dy * dy);
  return dist;
};

const cleanChart = () => {
  $(".anchor.end").each((i, endAnchor) => {
    $(endAnchor).removeClass("end");
  });
  $(".anchor.start").each((i, startAnchor) => {
    $(startAnchor).removeClass("start");
    const line = $(startAnchor).find(`.line`)[0];
    $(line).css({ width: 0 });
  });
};
const drawLines = (scale) => {
  $(".chart-container").each((i, chartContainer) => {
    const parent = $(chartContainer).find(".chart-item.parent")[0];
    if (parent) {
      $(chartContainer)
        .children(".chart-item.children")
        .each((y, children) => {
          const startAnchorId = $(children).data("start-anchor");
          const startAnchor = $(parent).find(`.anchor-${startAnchorId}`)[0];
          const endAnchorId = $(children).data("end-anchor");
          const endAnchor = $(children).find(`.anchor-${endAnchorId}`)[0];
          if (endAnchor && startAnchor) {
            $(startAnchor).addClass("start");
            const line = $(startAnchor).find(`.line`)[0];
            const width =
              distanceBetweenElems(startAnchor, endAnchor) / (scale / 100);
            $(line).css({ width });
            $(endAnchor).addClass("end");
          }
        });
    }
  });
};

const ParentContainer = ({ block, scale, top, left }) => {
  useEffect(() => {
    cleanChart();
    drawLines(scale);
  }, []);
  return (
    <>
      <div className="chart-item parent">
        {block.type === "group" && <Group group={block} />}
        {block.type === "agency" && <Agency agency={block} />}
        {block.type === "separator" && <Separator separator={block} />}
      </div>
      {block.children.map((child) => {
        const isContainer =
          child.type === "agency" ||
          child.type === "group" ||
          child.type === "separator";
        let extraProps = {};
        if (isContainer) {
          extraProps = {
            "data-start-anchor": child.pos,
            "data-end-anchor": oppositPosition[child.pos],
          };
        }
        let style = getPosition(child.pos, child.depth);
        if (child.type === "separator") {
          style = getSeparatorPosition(child.pos, child.depth);
        }
        return (
          <div
            key={`${block.type}-${uuidv4()}-${child.type}`}
            id={`${block.type}-${uuidv4()}-${child.type}`}
            className={classNames(
              "chart-item",
              isContainer && "chart-container",
              isContainer && child.type !== "separator" && "children",
            )}
            style={style}
            {...extraProps}
          >
            {!isContainer && <Profile profile={child} />}
            {isContainer && (
              <ParentContainer
                block={child}
                scale={scale}
                top={top}
                left={left}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

ParentContainer.propTypes = {
  block: PropTypes.object.isRequired,
  scale: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
};

export default ParentContainer;
