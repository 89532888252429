import React from "react";
import PropTypes from "prop-types";
import Hexagon from "./Hexagon";

const Agency = ({ agency }) => {
  let agencyImg = agency.image;
  if (agencyImg && process.env.NODE_ENV !== "development") {
    agencyImg = agencyImg.replace("http://", "https://");
  }
  return (
    <Hexagon keyName="agency" link={agency.link}>
      {agency.image && (
        <div className="hexagon-image">
          <img src={agencyImg} />
        </div>
      )}
      {!agency.image && <div className="title">{agency.title}</div>}
    </Hexagon>
  );
};
Agency.defaultProps = {
  agency: {
    title: "",
  },
};

Agency.propTypes = {
  agency: PropTypes.object,
};

export default Agency;
